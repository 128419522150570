import axios from "axios";
import VueAxios from "vue-axios";

class ApiService {
    static vueInstance;

    static init(app) {
        const csrf = document.querySelector('meta[name="csrf-token"]')

        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = '/';
        ApiService.vueInstance.axios.defaults.withCredentials = true;
        ApiService.vueInstance.axios.defaults.withXSRFToken = true;
        ApiService.vueInstance.axios.defaults.headers.common['Accept'] = 'application/json';
        ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] = 'application/json';
        ApiService.vueInstance.axios.defaults.headers.common['X-CSRF-Token'] = csrf.content;
    }

    static query(resource, params) {
        return ApiService.vueInstance.axios.get(resource, params);
    }

    static get(resource) {
        return ApiService.vueInstance.axios.get(`${resource}`);
    }

    static post(resource, params, headers = {}) {
        return ApiService.vueInstance.axios.post(`${resource}`, params, headers);
    }

    static update(resource, slug, params) {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    static put(resource, params) {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    static delete(resource) {
        return ApiService.vueInstance.axios.delete(resource);
    }
}

export default ApiService;
