<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.prescribed_antibiotics_question_html"></div>
        <div class="d-flex justify-content-between mb-5">
            <button class="btn btn-white rio-pdq-button w-165px me-1" :class="{'active' : store.formData.prescribed_antibiotics === true}" @click="select(true)">{{ langStore.lang.yes_answer }}</button>
            <button class="btn btn-white rio-pdq-button w-165px ms-1" :class="{'active' : store.formData.prescribed_antibiotics === false}" @click="select(false)">{{ langStore.lang.no_answer }}</button>
        </div>

        <div v-if="store.formData.prescribed_antibiotics">
            <div class="text-dark-blue fs-24px fw-bold mb-2">{{ langStore.lang.who_prescribed_title }}</div>
            <div class="text-dark-blue fs-16px mb-5">{{ langStore.lang.who_prescribed_desc }}</div>

            <textarea class="form-control input-border-radius mb-3" v-model="store.formData.who_prescribed_antibiotics" rows="5" placeholder="Write here..."></textarea>

            <div>
                <button class="btn btn-orange w-100 h-56px input-border-radius" :disabled="store.formData.who_prescribed_antibiotics == null" @click="store.nextStep">{{ langStore.lang.next }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';

const store = usePdqStore();
const langStore = useLangStore();

const select = (value) => {
    store.formData.prescribed_antibiotics = value
    if(!value) store.nextStep();
}
</script>