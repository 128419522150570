import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"
// Connects to data-controller="update-patient"
export default class extends Controller {

  static targets = ["personalDetailsForm", "clinicalDetailsForm", "personalEditButton", "personalCancelButton", "savePersonalButton", "clinicalEditButton", "clinicalCancelButton", "saveClinicalButton"]

  static values = {
    personalEditMode: {
      type: Boolean,
      default: false
    },
    clinicalEditMode: {
      type: Boolean,
      default: false
    },
    showPersonalAuto: {
      type: Boolean,
      default: false
    },
    showClinicalAuto: {
      type: Boolean,
      default: false
    },
  }

  connect() {
    if(this.showPersonalAutoValue) this.togglePersonalDetailsForm();
    if(this.showClinicalAutoValue) this.toggleClinicalDetailsForm();
    initIntlTelInput()
  }

  togglePersonalDetailsForm(){

    this.personalEditModeValue = !this.personalEditModeValue;

    this.toggleForm(this.personalEditModeValue, this.personalDetailsFormTarget, this.personalEditButtonTarget, this.savePersonalButtonTarget, this.personalCancelButtonTarget)
  }

  toggleClinicalDetailsForm(){

    this.clinicalEditModeValue = !this.clinicalEditModeValue;

    this.toggleForm(this.clinicalEditModeValue, this.clinicalDetailsFormTarget, this.clinicalEditButtonTarget, this.saveClinicalButtonTarget, this.clinicalCancelButtonTarget)

  }

  submitPersonalDetails(){
    this.addGsmCountryInput();
    this.personalDetailsFormTarget.requestSubmit()
  }

  submitClinicalDetails(){
    this.clinicalDetailsFormTarget.requestSubmit()
  }
  
  toggleForm(mode, form, editButton, saveButton, cancelButton){
    if(mode){

      saveButton.classList.remove('d-none')

      this.toggleButtonText(editButton, cancelButton);

      const textElements = form.getElementsByClassName('show-input');
      this.hideElements(textElements)

      const inputElements = form.getElementsByClassName('edit-input');
      this.showElements(inputElements)
    }else{

      saveButton.classList.add('d-none')

      this.toggleButtonText(cancelButton, editButton);

      const textElements = form.getElementsByClassName('show-input');
      this.showElements(textElements)

      const inputElements = form.getElementsByClassName('edit-input');
      this.hideElements(inputElements)
    }
  }

  toggleButtonText(toBeHidden, toBeShown){
    toBeHidden.classList.remove('d-block')
    toBeHidden.classList.add('d-none')

    toBeShown.classList.remove('d-none')
    toBeShown.classList.add('d-block')
  }

  showElements(elements){
    for(let i = 0;i < elements.length; i++){
      elements[i].classList.remove('d-none')
    }
  }

  hideElements(elements){
    for(let i = 0;i < elements.length; i++){
      elements[i].classList.add('d-none')
    }
  }

  addGsmCountryInput(){
    //set gsm code for patient
    const patientPhoneInput = this.personalDetailsFormTarget.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(patientPhoneInput);
    const patientGsmcountryInput = this.personalDetailsFormTarget.querySelectorAll('input[name=gsmcountrycode]')[0]
    patientGsmcountryInput.value = iti.selectedCountryData.dialCode
    // //set gsm code for caregiver
    const caregiverPhoneInput = this.personalDetailsFormTarget.getElementsByClassName('phone-input')[1]
    const iti2 = window.intlTelInputGlobals.getInstance(caregiverPhoneInput);
    const caregiverGsmCountryInput = this.personalDetailsFormTarget.querySelectorAll('input[name=caretakergsmcountrycode]')[0]
    caregiverGsmCountryInput.value = iti2.selectedCountryData.dialCode
  }
}
