<template>
    <div class="h-100 d-flex flex-column">
        <div class="mb-4 d-flex align-items-center justify-content-between">
            <back-button @click="store.prevStep"/>
            <close-button @click="createUpdateUrlParam('exit', true)"/>
        </div>
        <div class="fs-16px text-gray13 mb-3">{{ store.step }} {{ langStore.lang.progress_bar_text }}</div>
        <pdq-progress-bar class="mb-5" :step="store.step" />
        <component :is="'pdq-step-'+store.step" />
    </div>
    
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
import { createUpdateUrlParam } from '../../utils/create_update_url_param';

const store = usePdqStore();
const langStore = useLangStore();
const props = defineProps(['lang', 'leakTypes', 'sympthoms', 'healthWorkerTypes', 'pdq'])

store.formData = JSON.parse(props.pdq)
store.leakTypes = JSON.parse(props.leakTypes)
store.sympthoms = JSON.parse(props.sympthoms)
store.healthWorkerTypes = JSON.parse(props.healthWorkerTypes)

langStore.lang = JSON.parse(props.lang)

</script>