import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from "pinia/dist/pinia.cjs";
import ApiService from '../services/api_service';

import ButtonCounter from '../components/ButtonCounter.vue'
import PdqQuestionnaire from '../components/pdq/PdqQuestionnaire.vue';
import PdqProgressBar from '../components/pdq/PdqProgressBar.vue';
import BackButton from '../components/BackButton.vue';
import CloseButton from '../components/CloseButton.vue';
import PdqStep1 from '../components/pdq/PdqStep1.vue';
import PdqStep2 from '../components/pdq/PdqStep2.vue';
import PdqStep3 from '../components/pdq/PdqStep3.vue';
import PdqStep4 from '../components/pdq/PdqStep4.vue';
import PdqStep5 from '../components/pdq/PdqStep5.vue';
import PdqStep6 from '../components/pdq/PdqStep6.vue';
import PdqStep7 from '../components/pdq/PdqStep7.vue';
import PdqStep8 from '../components/pdq/PdqStep8.vue';
import PdqStep9 from '../components/pdq/PdqStep9.vue';

export const initVueApp = () => {
    const app = createApp()
    ApiService.init(app)

    const pinia = createPinia();
    app.use(pinia)

    app.component('ButtonCounter', ButtonCounter)
    app.component('PdqQuestionnaire', PdqQuestionnaire)
    app.component('PdqProgressBar', PdqProgressBar)
    app.component('BackButton', BackButton)
    app.component('CloseButton', CloseButton)
    app.component('PdqStep1', PdqStep1)
    app.component('PdqStep2', PdqStep2)
    app.component('PdqStep3', PdqStep3)
    app.component('PdqStep4', PdqStep4)
    app.component('PdqStep5', PdqStep5)
    app.component('PdqStep6', PdqStep6)
    app.component('PdqStep7', PdqStep7)
    app.component('PdqStep8', PdqStep8)
    app.component('PdqStep9', PdqStep9)

    return app;
}