import { Controller } from "@hotwired/stimulus";
import { Modal } from 'bootstrap'

// Connects to data-controller="pdf"
export default class extends Controller {
  static targets = ["modalBody", "canvas", "downloadButton", "loader"];

  static values = {
    pdfjsLib: {
      type: Object,
      default: window["pdfjs-dist/build/pdf"],
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    pageRendering: {
      type: Boolean,
      default: false,
    },
    pageNumPending: {
      type: Boolean,
      default: null,
    },
  };

  static doc = {};

  connect() {
    // The workerSrc property shall be specified.
    this.pdfjsLibValue.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${this.pdfjsLibValue.version}/pdf.worker.js`;
  }

  /**
   * If another page rendering in progress, waits until the rendering is
   * finised. Otherwise, executes rendering immediately.
   */
  queueRenderPage(num) {
    if (this.pageRenderingValue) {
      this.pageNumPendingValue = num;
    } else {
      this.renderPage(num);
    }
  }

  /**
   * Displays previous page.
   */
  prevPage() {
    if (this.pageNumValue <= 1) {
      return;
    }
    this.pageNumValue--;
    this.queueRenderPage(this.pageNumValue);
  }

  /**
   * Displays next page.
   */
  nextPage() {
    if (this.pageNumValue >= this.doc.numPages) {
      return;
    }
    this.pageNumValue++;
    this.queueRenderPage(this.pageNumValue);
  }

  getPage(url) {
    /**
     * Asynchronously downloads PDF.
     */
    return this.pdfjsLibValue
      .getDocument({
        url: url,
        withCredentials: false,
      })
      .promise.then((pdfDoc_) => {
        this.doc = pdfDoc_;
        document.getElementById("page_count").textContent = pdfDoc_.numPages;
        
        // Initial/first page rendering
        this.renderPage(this.pageNumValue);
      })
      .catch((r) => console.log(r));
  }

  /**
   * Get page info from document, resize canvas accordingly, and render page.
   * @param num Page number.
   */
  renderPage(num) {
    this.pageRenderingValue = true;
    // Using promise to fetch the page
    this.doc.getPage(num).then((page) => {
      const modalBody = document.getElementById("pdfModalBody");

      var scales = { 1: 3.2, 2: 4 },
        defaultScale = 3,
        scale = scales[window.devicePixelRatio] || defaultScale;

      var viewport = page.getViewport({ scale: scale });

      this.canvasTarget.height = viewport.height;
      this.canvasTarget.width = viewport.width;

      var displayWidth = modalBody.offsetWidth / page.view[2];

      this.canvasTarget.style.width = `${
        (viewport.width * displayWidth) / scale
      }px`;
      this.canvasTarget.style.height = `${
        (viewport.height * displayWidth) / scale
      }px`;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: this.canvasTarget.getContext("2d"),
        viewport: viewport,
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(() => {
        this.pageRenderingValue = false;
        if (this.pageNumPendingValue !== null) {
          // New page rendering is pending
          renderPage(this.pageNumPendingValue);
          this.pageNumPendingValue = null;
        }
      });
    });

    // Update page counters
    document.getElementById("page_num").textContent = num;
  }

  showLoader(){
    this.loaderTarget.classList.remove('d-none')
  }

  hideLoader(){
    this.loaderTarget.classList.add('d-none')
  }

  async showPdfModal(e){
    this.showLoader()
    const url = e.currentTarget.getAttribute('data-url')

    if(url.indexOf('media') != -1){
      window.location.href = url
      return
    }

    if(url == "") return;
    const el = document.getElementById('pdf-modal')
    const modal = new Modal(el)
    await modal.show();

    await this.getPage(url);
    this.downloadButtonTarget.href = url
    this.hideLoader()

    el.addEventListener('hidden.bs.modal', () => {
      this.doc.destroy()

      const context = this.canvasTarget.getContext('2d');
      context.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
      document.getElementById("page_num").textContent = ''
      document.getElementById("page_count").textContent = ''
    })
  }
}
