import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { inputsToJson } from "../utils/inputs_to_json"
// Connects to data-controller="update-physio"
export default class extends Controller {
  connect() {
    this.patientId = this.element.getAttribute('data-patient-id')
    const editModalEl = document.getElementById('edit-physio-exercise-modal')
    this.editModal = new Modal(editModalEl)
    editModalEl.addEventListener('hidden.bs.modal',() => {
      document.getElementById('exercise-video-iframe').src = ''
    })
    this.deleteModal = new Modal(document.getElementById('confirm-delete-exercise-modal')) 
  }

  setActiveWeek(e){
    const allSelectors = document.querySelectorAll('#big-physio-slider .physio-week-selector')
    allSelectors.forEach(item => item.classList.remove('active'))
    e.target.classList.add('active')

    const week = e.target.getAttribute('data-week')
    fetch('/patients/'+this.patientId+'/physio_for_week?week='+week, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))
  }

  getCurrentWeek(){
    return document.querySelector('#big-physio-slider .physio-week-selector.active').getAttribute('data-week')
  }

  nextSlide(){
    const slider = document.querySelector('#big-physio-slider .physio-slider')
    slider.scrollLeft += slider.offsetWidth;
  }

  prevSlide(){
    const slider = document.querySelector('#big-physio-slider .physio-slider')
    slider.scrollLeft -= slider.offsetWidth;
  }

  showEditModal(e){
    const week = document.querySelector('.physio-week-selector.active').getAttribute('data-week')
    const day = e.currentTarget.getAttribute('data-day')
    const id = e.currentTarget.getAttribute('data-id')
    const name = e.currentTarget.getAttribute('data-name')
    const desc = e.currentTarget.getAttribute('data-desc')
    const sets = e.currentTarget.getAttribute('data-sets')
    const reps = e.currentTarget.getAttribute('data-reps')
    const video = e.currentTarget.getAttribute('data-video')

    document.getElementById('edit-exercise-id').value = id
    document.getElementById('edit-exercise-week').value = week
    document.getElementById('edit-exercise-day').value = day
    document.getElementById('edit-exercise-modal-name').innerHTML = name
    document.getElementById('edit-exercise-modal-desc').innerHTML = desc
    document.getElementById('edit-exercise-reps').value = reps
    document.getElementById('edit-exercise-sets').value = sets
    document.getElementById('exercise-video-iframe').src = video

    this.editModal.show()
  }

  updateExercise(e){
    const repsInput = document.getElementById('edit-exercise-reps')
    const setsInput = document.getElementById('edit-exercise-sets')
    repsInput.classList.remove('is-invalid')
    setsInput.classList.remove('is-invalid')

    if(repsInput.value.trim() == ''){
      repsInput.classList.add('is-invalid')
    }
    if(setsInput.value.trim() == ''){
      setsInput.classList.add('is-invalid')
    }

    if(setsInput.classList.contains('is-invalid') || repsInput.classList.contains('is-invalid')){
      return e.preventDefault()
    }

    this.editModal.hide()
  }

  showDeleteModal(e){
    this.deleteModal.show()

    const week = document.querySelector('.physio-week-selector.active').getAttribute('data-week')
    const day = e.currentTarget.getAttribute('data-day')
    const id = e.currentTarget.getAttribute('data-id')

    document.getElementById('delete-exercise-id').value = id
    document.getElementById('delete-week').value = week
    document.getElementById('delete-day').value = day
  }

  async deleteExercise(e){

    e.currentTarget.classList.add("spinner", "spinner-light", "spinner-right")
    e.currentTarget.disabled = true

    const patientId = e.currentTarget.getAttribute('data-patient-id')
    const week = document.getElementById('delete-week').value
    const day = document.getElementById('delete-day').value
    const id = document.getElementById('delete-exercise-id').value

    const csrf = document.querySelector('meta[name="csrf-token"]')

    await fetch('/patients/'+patientId+'/delete_physio_exercise', {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        body_parts: this.selectedBodyParts,
        week: week,
        day: day,
        exercise_id: id
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))

    this.deleteModal.hide()
    e.currentTarget.classList.remove("spinner", "spinner-light", "spinner-right")
    e.currentTarget.disabled = false
  }

  showUpdatePlanModal(){
    const week = this.getCurrentWeek();
    
    const elementToClick = document.querySelector('#small-selector a[data-week="'+week+'"]')
    elementToClick.click()

    const el = document.getElementById('update-physio-plan-modal')
    this.updatePlanModal = new Modal(el)
    this.updatePlanModal.show()
  }

  async submitUpdatePlanModal(e){

    e.target.classList.add("spinner", "spinner-light", "spinner-right")
    e.target.disabled = true

    const patientId = this.element.getAttribute('data-patient-id')

    const physioInputs = this.getPhysioInputs()

    const csrf = document.querySelector('meta[name="csrf-token"]')

    await fetch('/patients/'+patientId+'/update_physio_plan', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        physio: physioInputs
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))

    this.updatePlanModal.hide()
    e.target.classList.remove("spinner", "spinner-light", "spinner-right")
    e.target.disabled = false
  }

  getPhysioInputs(){
    const physioInputs = document.querySelectorAll('.physio-input')
    const physioInputsArray = Array.from(physioInputs);
    return inputsToJson(physioInputsArray).physio
  }
}
