<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.saw_healthcare_question_html"></div>
        <div class="mb-5">
            <div v-for="(worker, key) in store.healthWorkerTypes" :key="key">
                <div 
                    class="btn btn-white rio-pdq-button w-100 text-start px-4 mb-3 d-flex align-items-center" 
                    :class="{ 'active' : isSelected(worker) }" 
                    @click="select(worker)"
                >
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div>
                            {{ langStore.lang.health_worker_options[key] }}
                        </div>
                        <div>
                            <img :src="isSelected(worker) ? checked : placeholder">
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="key == 'other_health_worker' && isSelected(worker)">
                    <textarea v-model="store.formData.other_health_worker_desc" class="form-control fs-16px text-gray13 input-border-radius" rows="5" placeholder="Write here..."></textarea>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-orange w-100 h-56px input-border-radius" :disabled="nextDisabled" @click="store.nextStep">{{ langStore.lang.next }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
import placeholder from '@images/rio-checked-placeholder.svg'
import checked from '@images/rio-checked.svg'
import { computed } from 'vue';

const store = usePdqStore();
const langStore = useLangStore();

const select = (value) => {
    const arr = store.formData.health_worker_types
    
    const index = arr.indexOf(value)

    if(index > -1){
        arr.splice(index, 1)
    }else arr.push(value);
    
    store.formData.health_worker_types = arr
}

const isSelected = (option) => {
    return store.formData.health_worker_types.includes(option)
}

const nextDisabled = computed(() => {
    return store.formData.health_worker_types.length == 0 || (isSelected(store.healthWorkerTypes.other_health_worker) && store.formData.other_health_worker_desc == null)
})
</script>