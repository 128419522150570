<template>
    <div class="d-flex flex-column justify-content-between flex-fill">
        <div>
            <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.sympthom_date_question_html"></div>
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.select_date }}</div>
            <input type="date" class="form-control mb-3" v-model="store.formData.sympthoms_date">
            <div class="color-light-blue2 fs-16px text-center">{{ langStore.lang.if_you_can_not_remember_date }}</div>
        </div>

        <div>
            <button class="btn btn-orange w-100 h-56px input-border-radius" :disabled="store.formData.sympthoms_date == null" @click="store.nextStep">{{ langStore.lang.next }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
const store = usePdqStore();
const langStore = useLangStore()
</script>