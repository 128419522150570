import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";

export const useCounterStore = defineStore('counter', () => {
  const count = ref(0)

  const increment = () => {
    count.value++
  }

  return { count, increment }
})